<template>
  <div class="choose-game" :class="{ mobile: isMobile }">
    <template v-if="isMobile">
      <StScrolledShadowContainer :is-scrolled="isScrolled">
        <StDrawerHeader>
          <h2 class="title">{{ t('bonuses.shouldChooseGame') }}</h2>
        </StDrawerHeader>
      </StScrolledShadowContainer>
    </template>
    <header v-else class="header">
      <h2 class="title">{{ t('bonuses.shouldChooseGame') }}</h2>
      <p class="subtitle">{{ t('bonuses.chooseGameSubtitle') }}</p>
    </header>
    <div ref="wrapper" class="content-wrapper">
      <p v-if="isMobile" class="subtitle">
        {{ t('bonuses.chooseGameSubtitle') }}
      </p>
      <div class="content">
        <GameCard
          v-for="game in games"
          :key="game.gameId"
          :game="game"
          type="bonus"
          :is-selected="selectedGameId === game.gameId"
          @click.prevent.stop="onCardClick(game)"
        />
        <template v-if="!games?.length">
          <template v-if="isMobile">
            <StSkeletonLoader
              v-for="i in 2"
              :key="i"
              height="140px"
              bg-color="var(--background-primary)"
              :shimmer-opacity="0.28"
              width="159px"
            />
          </template>
          <template v-else>
            <StSkeletonLoader
              v-for="i in skeletonParams.amount"
              :key="i"
              :height="skeletonParams.height"
              bg-color="var(--background-primary)"
              :shimmer-opacity="0.28"
              width="100%"
            />
          </template>
        </template>
      </div>
    </div>
    <footer class="footer">
      <StButton
        class="button"
        :size="buttonSize"
        :loading="isLoading"
        @click="onSubmitClick"
      >
        {{ t('bonuses.buttonChooseGame') }}
      </StButton>
    </footer>
  </div>
</template>

<script setup lang="ts">
import type { Game } from '@st/casino/types'
import { delay } from '@st/utils'
import { useBonusesDeps } from '../../useDeps'

const { t } = useI18n()
const router = useRouter()
const { isMobile } = usePlatform()
const stFetch = useRawStFetch()
const { open } = useToast()
const { GameCard, isGameLoading } = useBonusesDeps()

const wrapper = useTemplateRef('wrapper')
const { isScrolled } = useScrolled({ element: wrapper })

const props = defineProps<{
  gameIds: string[]
  userFreespinProgramId: string
}>()

const selectedGameId = ref<null | number>(null)
const loading = ref<boolean>(false)
const isLoading = computed(() => isGameLoading.value || loading.value)

function onCardClick(game: Game) {
  if (game.forbiddenByCountry) return

  const id = game.gameId
  if (selectedGameId.value === id) {
    selectedGameId.value = null
    return
  }
  selectedGameId.value = id
}

async function onSubmitClick() {
  if (!selectedGameId.value) {
    open({ label: t('bonuses.errorText'), type: 'negative' })
    return
  }
  loading.value = true

  const { data, error } = await stFetch('/user-freespin-campaign/create', {
    method: 'post',
    body: {
      userFreespinProgramId: Number(props.userFreespinProgramId),
      gameId: Number(selectedGameId.value),
    },
  })
  if (error) {
    open({ label: t('bonuses.errorSomethingWentWrong'), type: 'negative' })
  }

  if (data) {
    router.replace({
      path: `/casino/games/${selectedGameId.value}`,
      query: {
        modal: undefined,
      },
    })
  }
  await delay(2000)
  loading.value = false
}

const buttonSize = computed(() => (isMobile.value ? 'l' : 'xl'))
const { data: games } = useStFetch('/casino/game/find', {
  method: 'POST',
  server: false,
  body: {
    params: {
      gameId: Array.isArray(props.gameIds)
        ? props.gameIds?.map((i) => Number(i))
        : [Number(props.gameIds)],
    },
    pagination: {
      page: 0,
      perPage: 50,
      orderBy: [
        {
          fieldName: 'forbiddenByCountry' as const,
          sortOrder: 'ASC' as const,
        },
        {
          fieldName: 'name' as const,
          sortOrder: 'DESC' as const,
        },
      ],
    },
  },

  transform: (response) => response.data,
})

const skeletonParams = computed(() =>
  isMobile.value
    ? {
        amount: 2,
        height: '144px',
      }
    : {
        amount: 3,
        height: '160px',
      },
)
</script>

<style scoped>
.choose-game {
  position: relative;
  width: 596px;

  &::after {
    content: '';

    position: absolute;
    right: 0;
    bottom: -32px;
    left: 0;
    transform-origin: 50% 0;
    transform: scaleY(0);

    height: 56px;

    background-color: var(--background-base);
    border-bottom-right-radius: var(--border-radius-150);
    border-bottom-left-radius: var(--border-radius-150);

    transition: transform 0.3s ease-in-out;
  }

  &.is-error {
    &::after {
      transform: scaleY(1);
    }
  }

  .header {
    padding: var(--spacing-300) var(--spacing-300) var(--spacing-250)
      var(--spacing-300);
  }

  .title {
    margin: 0;
    margin-bottom: var(--spacing-050);
    font: var(--desktop-text-2xl-semibold);
  }

  .subtitle {
    margin: 0;
    font: var(--desktop-text-md-medium);
    color: var(--text-secondary);
  }

  .content-wrapper {
    overflow: hidden scroll;
  }

  .content {
    display: grid;
    grid-template-columns: repeat(3, 172px);
    gap: var(--spacing-200) var(--spacing-150);

    max-height: 520px;
    padding: 0 var(--spacing-300);

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .footer {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-200);
    align-items: center;

    padding: var(--spacing-300);
  }

  .button {
    width: 100%;
  }
}

.choose-game.mobile {
  width: 100%;
  padding-top: var(--spacing-250);

  .header {
    padding: 0 var(--spacing-200);
  }

  .title {
    padding: 0;
    font: var(--mobile-title-2-semibold);
  }

  .subtitle {
    padding: 0 var(--spacing-200);
    font: var(--mobile-text-content-regular);
    color: var(--text-tertiary);
  }

  .content {
    grid-template-columns: repeat(2, minmax(160px, 200px));
    gap: var(--spacing-200) var(--spacing-150);
    justify-content: center;

    max-height: 75dvh;
    padding: var(--spacing-200);
    padding-bottom: 0;
  }

  .error {
    font: var(--mobile-caption-1-regular);
  }

  .footer {
    padding: var(--spacing-200);
  }
}

.v-enter-active,
.v-leave-active {
  transition:
    opacity 0.3s ease-in-out,
    transform 0.3s ease-in-out;
}

.v-enter-from,
.v-leave-to {
  transform: translateY(-10px);
  opacity: 0;
}
</style>
